/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Divider, Text, ContactForm, Image, TitleMain } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Hledá se talent "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1lxt3af css-42e4bw --full" name={"7m3t7pjuyrt"} style={{"paddingTop":0,"paddingBottom":0}} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/72899b705095480bafceaab0b6ea5ce9_con=110_ove=000000x25__s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/72899b705095480bafceaab0b6ea5ce9_con=110_ove=000000x25__s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/72899b705095480bafceaab0b6ea5ce9_con=110_ove=000000x25__s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/72899b705095480bafceaab0b6ea5ce9_con=110_ove=000000x25__s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/72899b705095480bafceaab0b6ea5ce9_con=110_ove=000000x25__s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/72899b705095480bafceaab0b6ea5ce9_con=110_ove=000000x25__s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/72899b705095480bafceaab0b6ea5ce9_con=110_ove=000000x25__s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/72899b705095480bafceaab0b6ea5ce9_con=110_ove=000000x25__s=3000x_.JPG);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--border4 pb--60 pt--60" name={"2rh1d9f7a6o"} border={"4"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--36" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Důležité informace</span>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--left fs--24" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Termín:</span> <br>15.-17.11.2025<br><span style=\"font-weight: bold;\"><br>Místo:</span> <br>KD Dukla Pardubice<br><br></span><span style=\"font-weight: bold;\">Startovné:<br></span>250 Kč / den&nbsp;<br><br><span style=\"font-weight: bold;\">Registrace:<br></span>do 1.9.2025<br><br><span style=\"font-weight: bold;\">Ubytování:<br></span>zajištěno v ceně<br><br><span style=\"font-weight: bold;\">Stravování:<br></span>zajištěno v ceně<br><br><span style=\"font-weight: bold;\">Parkování:<br></span>zajištěno v ceně<br><br><span style=\"font-weight: bold;\">Program:<br></span>zajištěn po celou dobu<br><br><span style=\"font-weight: bold;\">Pedagogický dozor:<br></span>zajištěn po celou dobu<br><br><span style=\"font-weight: bold;\">Zdravotnický dozor:<br></span>zajištěn po celou dobu<br><br><span style=\"font-weight: bold;\">Psycholog:<br></span>přítomen po celou dobu<br>"}>
              </Subtitle>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box fs--24" content={"<span style=\"font-weight: bold;\">K vystoupení dětí:</span>"}>
              </Subtitle>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\">Každé dítě může vystupovat maximálně 2x. Jednou samo za sebe a jednou ve skupině. Vystoupení dětí hodnotí porota složená z osobností, které nás podporují. Letos bude tříčlenná. Mezi prvními můžeme prozradit porotce, kterým je sportovec, několikanásobný mistr Evropy, vítěz Světového poháru, účastník dvou letních OH a olympijský medailista z LOH v Paříži. Porotci hodnotí výkony dětí slovně po každém vystoupení a bodově. Bodování je tajné a dozví se jej děti až na slavnostním vyhlášení v neděli při předávání cen.&nbsp;<br></span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--24" content={"<span style=\"font-weight: bold;\">Talentu se meze nekladou...</span>"}>
              </Subtitle>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"color: rgb(0, 0, 0);\">Naše talentová soutěž dokazuje, že to nemusí a není jen o tanci a zpěvu. V minulých letech děti hrály divadlo, předvedly moderní gymnastiku, hrály na bubny, kouzlily, malovaly nebo dělaly parkour. Necháváme to čistě na dětech. Prosíme ale ty, kteří děti připravují a pomáhají je, aby je vedly k soudnosti, abychom společně předešly možnému zklamání před sebou i všemi ostatními.&nbsp;</span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--24" content={"<span style=\"font-weight: bold;\">Registrace dětí, dětských domovů</span>"}>
              </Subtitle>

              <Text className="text-box text-box--justify" content={"<span style=\"color: var(--black);\">Na registraci je času dost. Přesto je kapacita již téměř naplněná. Registrace za děti vyplňuje pověřená osoba za dětský domov, která následně komunikuje veškeré záležitosti. Prosíme o včasné informování jakýchkoliv změn. Registrace je závazná a prosíme o provedení do 1.9.2025</span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--24" content={"<span style=\"font-weight: bold;\">Startovné dětí</span>"}>
              </Subtitle>

              <Text className="text-box text-box--justify" content={"<span style=\"color: rgb(0, 0, 0);\">Startovné se hradí až po registraci dětského domova a to v měsíci říjnu. Konkrétně od 1.10.2025 do 31.10.2025. Startovné jsme pro VŠECHNY dětské domovy stanovily na 250 Kč / dítě / den. V ceně je zahrnuto stravování po celou dobu akce, raut pro děti i dospělé, ubytování, parkování a program pro děti. Výše startovného se odvíjí od zvýšených nákladů (energie) na pronájem celého kulturního domu, domova mládeže, jídelny, parkování a dalších věcí.&nbsp;&nbsp;</span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--24" content={"<span style=\"font-weight: bold;\">Akce s přesahem pro děti i dospělé</span>"}>
              </Subtitle>

              <Text className="text-box text-box--justify" content={"<span style=\"color: var(--black);\">Tato akce má přesah daleko větší než si možná někdo pod pojmem talentová soutěž může představit. Představte si, kdy v životě se Vám podaří na jednom místě vidět děti a dospělé z 25-30 dětských domovů z naší země. A to není vše. Program pro děti je pestrý a pečlivě připravený, ale my myslíme i na tety, strejdy, ředitele z DD, které vzájemně dlouhodobě propoujeme. A tato akce je k tomu jako stvořená. Můžete se těšit na řadu zajímavých hostů z řad VŠ pedagogů, právníků, psychologů, ale také našich úžasných partnerů, sponzorů a těch, co s námi pomáhají dětem v dětských domovech.</span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--24" content={"<span style=\"font-weight: bold;\">Příjezd, odjezd</span>"}>
              </Subtitle>

              <Text className="text-box text-box--justify" content={"<span style=\"color: var(--black);\">VŠECHNY dětské domovy prosíme o příjezd v sobotu 15.11.2025 do 12:00. Odjezd pro VŠECHNY dětské domovy bude v pondělí 17.11.2025 od 11:00. Není problém se poté domluvit na případném přívozu či odvozu dětí z hlavního nádraží v případě potřeby. Jen prosíme tuto informaci sdělit dopředu. Poté Vás rádi vyzvedneme na hlavním nádraží a odvezeme do centra dění na Dukle v Pardubicích.&nbsp;</span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--24" content={"<span style=\"font-weight: bold;\">Program</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">Program celé akce budeme průběžně aktualizovat na našich sociálních sítích, ale každý dětský domov jej samozřejmě obdrží minimálně 2 měsíce před konáním talentové soutěže.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"ihvsmwnwnjp"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Divider >
              </Divider>

              <Title className="title-box title-box--center fs--30" content={"<span style=\"font-weight: bold;\"><span style=\"color: var(--color-custom-1);\">Realizační tým</span><br></span>info@detivakci.org<br>"}>
              </Title>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box subtitle-box--left fs--22" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Oddělení PR a komunikace&nbsp;</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">vedoucí:</span>\t<br>Mgr. Jan Klepárník<br>Mgr. Tereza Čaladi\n<br><span style=\"font-weight: bold;\">členové:</span>\t<br>Kateřina Klimková <br>Dominika Maňáková <br>Barbora Tvrďochová<br></span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--22" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Oddělení IT a technická podpora\n</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">vedoucí:</span>\t<br>Ing. Daniel Šimek\n<br><span style=\"font-weight: bold;\">členové:</span>\t<br>Filip Drábek<br>Martin Horečka </span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--22" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Oddělení volnočasových aktivit\n</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">vedoucí:</span>\t<br>Mgr. Elen Sytko<br>Bc. Adéla Zmrzlíková\n<br><span style=\"font-weight: bold;\">členové:</span>\t<br>Lucie Martiniaková<br>Michaela Rysová <br>Petra Hápová <br>Markéta Trundová<br>Štěpánka Hromádková<br>Radim Harapát<br>Markéta Samková</span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--22" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Oddělení péče o partnery a sponzory \n</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">vedoucí:</span>\t<br>Bc. Kateřina Lévai\n<br><span style=\"font-weight: bold;\">členové:</span>\t<br>Mgr. Lydia Petera</span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--22" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Oddělení podpory a dokumentace\n</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--16" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">vedoucí:</span>\t<br>Mgr. Lucie Vtípilová <br><span style=\"font-weight: bold;\">členové:</span>\t<br>Gabriela Rohelová<br>Zdeněk Steinigl<br></span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--22" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Oddělení stravování a ubytování \n</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">vedoucí:</span> <br>Emilie Haničáková&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border4" style={{"paddingTop":24,"paddingBottom":22}} name={"4id3a09bxd4"} border={"4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Registrační formulář</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--16" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">pro dětské domovy</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 fs--14" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Dětský domov","type":"text","required":true,"placeholder":"","id":"g70xhyn4465h"},{"name":"Kontaktní osoba","type":"email","required":true,"placeholder":"","id":"twfthqfo12a4"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"egzi2doaamjg"},{"name":"Jména účinkujících dětí, název vystoupení","type":"textarea","required":true,"placeholder":"","id":"zg9s8jd4sjj"},{"id":"18jz4d2ev6nc","type":"text","name":"Potřeby k vystoupení dětí","required":true},{"id":"knfmtu5b8y2","type":"text","name":"Zdravotní a stravovací omezení ","required":true},{"id":"gfufjzxnq3ap","type":"text","name":"Celkový počet osob (děti i dospělí)","required":true},{"name":"Odeslat","type":"submit","id":"fvlrwkk8c7ny","align":"center"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border3 --center" style={{"paddingBottom":10,"paddingTop":14}} name={"45dl2da8a1p"} border={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"font-weight: bold;\">Registrované <br>dětské domovy</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border3 pb--60 pt--60" name={"9c2h5x4thln"} border={"3"}>
          
          <ColumnWrap className="column__flex --shape5 --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape2 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/2104acde65a44cf1bf936adc216d33ec_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/2104acde65a44cf1bf936adc216d33ec_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/2104acde65a44cf1bf936adc216d33ec_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/2104acde65a44cf1bf936adc216d33ec_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/2104acde65a44cf1bf936adc216d33ec_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/6ce84ba17aa44a189a91a6696a8de43e_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/6ce84ba17aa44a189a91a6696a8de43e_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/6ce84ba17aa44a189a91a6696a8de43e_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/6ce84ba17aa44a189a91a6696a8de43e_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/6ce84ba17aa44a189a91a6696a8de43e_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/1699cbf189424cf9a6be961185785121_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/1699cbf189424cf9a6be961185785121_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/1699cbf189424cf9a6be961185785121_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/1699cbf189424cf9a6be961185785121_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/1699cbf189424cf9a6be961185785121_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape2 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/a9bfc0d0e838434390f0e599918ff71f_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/a9bfc0d0e838434390f0e599918ff71f_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/a9bfc0d0e838434390f0e599918ff71f_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/a9bfc0d0e838434390f0e599918ff71f_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/a9bfc0d0e838434390f0e599918ff71f_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/9683a6b981c948f8ba6041131944e215_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/9683a6b981c948f8ba6041131944e215_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/9683a6b981c948f8ba6041131944e215_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/9683a6b981c948f8ba6041131944e215_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/9683a6b981c948f8ba6041131944e215_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/8a1937d0ecc2455db17a0c5e7beb59f7_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/8a1937d0ecc2455db17a0c5e7beb59f7_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/8a1937d0ecc2455db17a0c5e7beb59f7_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/8a1937d0ecc2455db17a0c5e7beb59f7_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/8a1937d0ecc2455db17a0c5e7beb59f7_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape2 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/1f30a49e010e48beb18bb200b610df21_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/1f30a49e010e48beb18bb200b610df21_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/1f30a49e010e48beb18bb200b610df21_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/1f30a49e010e48beb18bb200b610df21_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/1f30a49e010e48beb18bb200b610df21_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/294c00d244ce4b5c91cdd50cfd118a50_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/294c00d244ce4b5c91cdd50cfd118a50_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/294c00d244ce4b5c91cdd50cfd118a50_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/294c00d244ce4b5c91cdd50cfd118a50_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/294c00d244ce4b5c91cdd50cfd118a50_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/0c779bc26fdc4303bcc793124cd2c0d4_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/0c779bc26fdc4303bcc793124cd2c0d4_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/0c779bc26fdc4303bcc793124cd2c0d4_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/0c779bc26fdc4303bcc793124cd2c0d4_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/0c779bc26fdc4303bcc793124cd2c0d4_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape2 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/48366f0a7b6d4b0db071b5c551ca0145_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/48366f0a7b6d4b0db071b5c551ca0145_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/48366f0a7b6d4b0db071b5c551ca0145_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/48366f0a7b6d4b0db071b5c551ca0145_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/48366f0a7b6d4b0db071b5c551ca0145_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/d4401f119fdd4e98962f66d2d616792d_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/d4401f119fdd4e98962f66d2d616792d_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/d4401f119fdd4e98962f66d2d616792d_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/d4401f119fdd4e98962f66d2d616792d_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/d4401f119fdd4e98962f66d2d616792d_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/9b45ced39a2049d48f088d16ea4dda79_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/9b45ced39a2049d48f088d16ea4dda79_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/9b45ced39a2049d48f088d16ea4dda79_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/9b45ced39a2049d48f088d16ea4dda79_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/9b45ced39a2049d48f088d16ea4dda79_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape2 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/4d86ccb2f3af4ce287d0f769044124cd_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/4d86ccb2f3af4ce287d0f769044124cd_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/4d86ccb2f3af4ce287d0f769044124cd_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/4d86ccb2f3af4ce287d0f769044124cd_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/4d86ccb2f3af4ce287d0f769044124cd_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/5258879c800640fc9887a3fddbe923dc_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/5258879c800640fc9887a3fddbe923dc_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/5258879c800640fc9887a3fddbe923dc_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/5258879c800640fc9887a3fddbe923dc_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/5258879c800640fc9887a3fddbe923dc_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/dda9b7832b42435e94881289f78d9ed9_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/dda9b7832b42435e94881289f78d9ed9_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/dda9b7832b42435e94881289f78d9ed9_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/dda9b7832b42435e94881289f78d9ed9_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/dda9b7832b42435e94881289f78d9ed9_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape2 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/737ecc2fa3704fcab9ef1d88da64cd3c_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/737ecc2fa3704fcab9ef1d88da64cd3c_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/737ecc2fa3704fcab9ef1d88da64cd3c_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/737ecc2fa3704fcab9ef1d88da64cd3c_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/737ecc2fa3704fcab9ef1d88da64cd3c_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/83d47ada667044f788647c266ef4400b_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/83d47ada667044f788647c266ef4400b_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/83d47ada667044f788647c266ef4400b_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/83d47ada667044f788647c266ef4400b_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/83d47ada667044f788647c266ef4400b_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/4fa86df8403f4e25a4e436be60b1ecdf_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/4fa86df8403f4e25a4e436be60b1ecdf_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/4fa86df8403f4e25a4e436be60b1ecdf_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/4fa86df8403f4e25a4e436be60b1ecdf_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/4fa86df8403f4e25a4e436be60b1ecdf_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape2 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/602498c855b844e793d379b2aab3d209_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/602498c855b844e793d379b2aab3d209_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/602498c855b844e793d379b2aab3d209_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/602498c855b844e793d379b2aab3d209_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/602498c855b844e793d379b2aab3d209_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/ee8a887132e74b28bcb175d2629877dd_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/ee8a887132e74b28bcb175d2629877dd_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/ee8a887132e74b28bcb175d2629877dd_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/ee8a887132e74b28bcb175d2629877dd_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/ee8a887132e74b28bcb175d2629877dd_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/58de2edfba5d4f11950731e44b06d859_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/58de2edfba5d4f11950731e44b06d859_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/58de2edfba5d4f11950731e44b06d859_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/58de2edfba5d4f11950731e44b06d859_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/58de2edfba5d4f11950731e44b06d859_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border3 --center" name={"itijl92bi6i"} style={{"paddingBottom":20,"paddingTop":13}} border={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <TitleMain className="title-box title-box--shadow4 title-box--center fs--30" content={"<span style=\"font-weight: bold;\">Naši partneři<br></span>"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border3 pb--60 pt--60" name={"r6adp3flo1k"} border={"3"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape2 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/9fd29ba447544e8bafbf4f8b5de52e31_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/9fd29ba447544e8bafbf4f8b5de52e31_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/9fd29ba447544e8bafbf4f8b5de52e31_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/9fd29ba447544e8bafbf4f8b5de52e31_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/9fd29ba447544e8bafbf4f8b5de52e31_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape2 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/dff6187d80a541ebb098e283adb8d259_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/dff6187d80a541ebb098e283adb8d259_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/dff6187d80a541ebb098e283adb8d259_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/dff6187d80a541ebb098e283adb8d259_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/dff6187d80a541ebb098e283adb8d259_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape2 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/6168971eeeba4d46a1e6164f1c3707c0_s=860x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/6168971eeeba4d46a1e6164f1c3707c0_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/6168971eeeba4d46a1e6164f1c3707c0_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/6168971eeeba4d46a1e6164f1c3707c0_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/6168971eeeba4d46a1e6164f1c3707c0_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}